import { debounce } from "lodash";
import type { RefObject } from "react";
import { useEffect, useRef, useState } from "react";

type Node<T extends HTMLElement = HTMLElement> = RefObject<T> | null;
interface RefHandlerOptions<T extends HTMLElement = HTMLElement> {
  autoRef?: boolean;
  delay?: number;
  targetRef?: Node<T>;
}
/** A debounced (element) hover state tracker, by ref */
export const useDebouncedHoverWithRef = getDebouncedRefHandlerHook("mouseenter", "mouseleave");
/** A debounced (element) focus state tracker, by ref */
export const useDebouncedFocusWithRef = getDebouncedRefHandlerHook("focus", "blur", true);

function getDebouncedRefHandlerHook(
  activationEvent: keyof HTMLElementEventMap,
  deactivationEvent: keyof HTMLElementEventMap,
  focusable = false
) {
  return <T extends HTMLElement>(options: RefHandlerOptions<T> = {}): [boolean, Node<T>] => {
    const { targetRef, autoRef = false, delay = 300 } = options;
    const [value, setValue] = useState<boolean>(false);
    const ref = targetRef || useRef<T>(null);

    useEffect(() => {
      const handleActivate = debounce(() => setValue(true), delay);
      const handleDeactivate = () => {
        setValue(false);
        handleActivate.cancel();
      };

      const node: T | null = autoRef && focusable ? getFocusableElement<T>(ref?.current) : ref?.current;

      if (node) {
        node.addEventListener(activationEvent, handleActivate);
        node.addEventListener(deactivationEvent, handleDeactivate);
        if (autoRef) {
          node.addEventListener("click", handleDeactivate);
        }
      }

      return () => {
        if (node) {
          node.removeEventListener(activationEvent, handleActivate);
          node.removeEventListener(deactivationEvent, handleDeactivate);
          if (autoRef) {
            node.removeEventListener("click", handleDeactivate);
          }
        }
      };
    }, [ref, autoRef, delay]);

    return [value, ref];
  };
}

function getFocusableElement<T extends HTMLElement>(ref: T | null): T | null {
  if (ref) {
    const focusEl = ref.querySelector("button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])");
    if (focusEl) {
      return focusEl as T;
    }
  }
  return ref;
}
