import { styled } from "baseui";
import React from "react";
import { CommandIcon } from "../icons/CommandIcon";
import { OptionIcon } from "../icons/OptionIcon";
import { ShiftIcon } from "../icons/ShiftIcon";
import { expandBorderRadii } from "../utils";

export const ShortcutKey = styled<{ $kind: "light" | "dark" }, "div">("div", ({ $theme, $kind }) => ({
  minWidth: $theme.sizing.scale700,
  height: $theme.sizing.scale700,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: $kind == "dark" ? $theme.colors.backgroundInverseTertiary : $theme.colors.backgroundSecondary,
  ...expandBorderRadii($theme.borders.radius100),
}));

const isMac = typeof navigator !== "undefined" && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export const shortcutKeyText = (key: string) => {
  key = key.toUpperCase();
  if (key == "MOD" || key == "META") {
    if (isMac) {
      return <CommandIcon $size={16} />;
    } else {
      return "CTRL";
    }
  } else if (key == "OPTION") {
    if (isMac) {
      return <OptionIcon $size={16} />;
    } else {
      return "ALT";
    }
  } else if (key == "SHIFT") {
    return <ShiftIcon $size={16} />;
  } else if (key == "ENTER" || key == "RETURN") {
    return "↵";
  } else {
    return key;
  }
};
