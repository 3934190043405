import type { IAnyModelType, Instance } from "@gadgetinc/mobx-quick-tree";
import React from "react";
import { HotKeys } from "react-hotkeys-ce";
import type { SelectableTargetForwardProps } from "../../navigation-tree/SelectableContainer";
import { ConfigureSelectable } from "../../navigation-tree/SelectableContainer";
import { SelectedButtonStyler } from "../../navigation-tree/hooks";
import { useFlushSyncCallback } from "../../utils";
import type { ToolTipLabelSize } from "../ToolTip";
import { ToolTip } from "../ToolTip";

export const SelectableButton = <Model extends IAnyModelType>(props: {
  node: Instance<Model>;
  subNodeKey?: string;
  onAction: () => void;
  label?: string;
  labelSize?: ToolTipLabelSize;
  children: (
    forwardProps: SelectableTargetForwardProps,
    onClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  ) => React.ReactNode;
}) => {
  const onClick = useFlushSyncCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      props.onAction();
    },
    [props.onAction]
  );

  return (
    <ConfigureSelectable node={props.node} subNodeKey={props.subNodeKey} engagementBoundary={false} getStyles={SelectedButtonStyler}>
      {(forwardProps) => {
        return (
          <HotKeys
            handlers={{
              ACTION: (event) => {
                event?.preventDefault();
                event?.stopPropagation();
                props.onAction();
              },
            }}
            keyMap={{ ACTION: ["enter", "space"] }}
            style={{ lineHeight: 0, display: "contents" }}
          >
            {!props.label ? (
              props.children(forwardProps, onClick)
            ) : (
              <ToolTip label={props.label} labelSize={props.labelSize}>
                {props.children(forwardProps, onClick)}
              </ToolTip>
            )}
          </HotKeys>
        );
      }}
    </ConfigureSelectable>
  );
};
