import type { SnapshotOrInstance } from "@gadgetinc/mobx-quick-tree";
import { useStyletron } from "baseui";
import { action } from "mobx";
import type React from "react";
import { useContext, useEffect, useMemo } from "react";
import type { GadgetModelType } from "state-trees/src/utils";
import type { StyleObject } from "styletron-react";
import { assert } from "../utils";
import type { ISpatialSelection, SelectableTargetOptions } from "./SelectableTarget";
import { MoveInitiator, SelectableTarget } from "./SelectableTarget";
import { SpaceNavigator } from "./SpaceNavigator";
import { CurrentSelectableContext, SelectionContext, SpaceNavigatorContext } from "./contexts";

export const DEFAULT_SUB_NODE_KEY = "<root>";

export const isDefaultSubNodeKey = (key: string | undefined) => {
  return typeof key == "undefined" || key == DEFAULT_SUB_NODE_KEY;
};

export const packSelectableKey = (node: SnapshotOrInstance<GadgetModelType>, subNodeKey?: string) => {
  return `${node.type}///${node.key}///${subNodeKey || DEFAULT_SUB_NODE_KEY}`;
};

export const unpackSelectableKey = (selectableKey: string): [string, string | undefined] => {
  const [_type, key, subNodeKey] = selectableKey.split("///");
  return [key, subNodeKey == DEFAULT_SUB_NODE_KEY ? undefined : subNodeKey];
};

/**
 * Get the current ISpatialSelection from react context
 * This is useful outside this mini-package too for getting the selection without having to thread it through all the props, and it's fine to use it for that
 */
export const useSelection = <Selection extends ISpatialSelection = ISpatialSelection>() => {
  return assert(useContext(SelectionContext), "cant use space navigation hooks outside of a SpaceNavigatorRoot") as Selection;
};

export const useSpaceNavigator = <Selection extends ISpatialSelection = ISpatialSelection>() => {
  return assert(
    useContext(SpaceNavigatorContext),
    "cant use space navigation hooks outside of a SpaceNavigatorRoot"
  ) as SpaceNavigator<Selection>;
};

export interface SelectionState {
  isSelected: boolean;
  isDescendantSelected: boolean;
  isHovered: boolean;
  selectable: SelectableTarget;
}

export const useSelectionState = (selectable: SelectableTarget): SelectionState => {
  const selection = useSelection();
  const spaceNavigator = useSpaceNavigator();
  return {
    get isSelected() {
      return spaceNavigator.isSelected(selection, selectable);
    },
    get isDescendantSelected() {
      return spaceNavigator.isAncestorOfSelected(selectable, selection);
    },
    get isHovered() {
      return selection.isHovered(selectable);
    },
    selectable,
  };
};

export const SelectedButtonStyler = (styles: StyleObject): StyleObject => ({ ...styles, outlineOffset: "1px" });

export const useSelectedStyles = (
  selectable: SelectableTarget,
  getStyles?: (styles: StyleObject, isSelected: boolean) => StyleObject
): StyleObject => {
  const { isSelected } = useSelectionState(selectable);
  const [_, $theme] = useStyletron();

  const styles: StyleObject = {
    cursor: "pointer",
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineOffset: "0",
    MozOutlineRadius: $theme.sizing.scale0,
    outlineColor: "rgba(0,0,0,0)",
    transitionProperty: "outline-color, background-color, border-color",
    transitionDuration: $theme.animation.timing200,
    transitionTimingFunction: $theme.animation.easeOutCurve,
  };

  if (isSelected) {
    styles.outlineColor = $theme.colors.borderFocus;
    styles.boxShadow = "none";
  }

  return getStyles ? getStyles(styles, isSelected) : styles;
};

export interface SelectableMouseEventHandlers {
  onMouseEnter: React.MouseEventHandler;
  onMouseLeave: React.MouseEventHandler;
  onClick: React.MouseEventHandler;
}

export const useSelectableMouseEventHandlers = (selectable: SelectableTarget): SelectableMouseEventHandlers => {
  const selection = useSelection();
  return useMemo(
    () => ({
      onMouseEnter: action(() => {
        if (selectable.navigator.debug) console.debug("space-node-mouse-enter", { key: selectable.selectableKey, selectable });
        selection.hovered.push(selectable);
      }),
      onMouseLeave: action(() => {
        if (selectable.navigator.debug) console.debug("space-node-mouse-leave", { key: selectable.selectableKey, selectable });
        selection.hovered.remove(selectable);
      }),
      onClick: (event) => {
        if (event.isPropagationStopped()) return;
        event.stopPropagation();
        selectable.navigator.callbacks.onClick(selectable.navigator, selectable);
      },
      onFocus: (event: FocusEvent) => {
        //We have to allow the focus event to bubble or hotkeys stops working entirely, as it listens internally to when something
        //becomes focused so that it knows if it should apply hotkeys to that item or not)

        //This means, this focus event gets handled by several different selectables, including the parents of the one we care about,
        //so we should only focus if the original target (event origin) is the same as the element handling the focus event i.e. currentTarget
        if (event.target === event.currentTarget) {
          const target = selectable.navigator.navigateDirectly(selection, selectable);
          selection.moveToSelectableTarget(target, MoveInitiator.Keyboard);
        }
      },
    }),
    [selectable, selection.hovered, selection.moveToSelectableTarget]
  );
};

/**
 * Module private hook that impelements the mounting / unmounting tracking for a SelectableTarget
 * Shared between everything that might mount a selectable
 * __Warning__: This hook is super sensitive to the `options` changing as the SelectableTarget needs to be unmounted and remounted if those options change. This doesn't break the functionality, but it's a lot of extra work. In particular, try to make sure that `onBlur` and `onFocus` callbacks are memoized and then the `options` themselves are memoized when invoking this hook.
 */
export const useSelectableTarget = (options: SelectableTargetOptions): SelectableTarget => {
  const spaceNavigator = useSpaceNavigator();
  // If we're nesting selectables, use the current level to register the selectable so it can track the tree
  // If we don't have an existing selectable, this is a root, so register it directly with the space navigator to start bookkeeping
  const currentLevel = useContext(CurrentSelectableContext);

  const selectable = useMemo(() => {
    const parent = currentLevel instanceof SpaceNavigator ? null : currentLevel;
    return new SelectableTarget(spaceNavigator, parent, options);
  }, [spaceNavigator, currentLevel, options]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const selection = useSelection();

  useEffect(() => {
    selectable.mounted = true;
    if (selectable.navigator.debug) console.debug("space-node-mount", { key: selectable.selectableKey, selectable });
    currentLevel.mountChild(selectable);
    void selectable.emit("mount", selectable);

    return () => {
      selectable.mounted = false;
      if (selectable.navigator.debug) console.debug("space-node-unmount", { key: selectable.selectableKey, selectable });
      currentLevel.unmountChild(selectable);
      void selectable.emit("unmount", selectable);
    };
  }, [currentLevel, selectable]);

  useEffect(() => {
    // Sometimes the in-memory selection has a selectedKey set before a target for that key has been mounted, for example, on page load if there is an anchor in the URL bar. We're still rendering the page but we know what we want to select once we're done. This implements that -- if we are mounting a selectable and it is immediately supposed to be selected, we focus it.
    // We use focus for things like hotkeys that care about what element in the document is currently selected for its FocusOnlyKeyEventStrategy, so it's important we do this as soon as possible.
    if (selection.selectedKey == selectable.selectableKey) {
      setTimeout(() => options.elementRef?.current?.focus(), 0);
    }
    /**
     * We only do this automatic focus-on-mount for the first render, and let the rest of the selection subsystem handle focusing new selections
     * @see `useSpatialSelectionEffects`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return selectable;
};
