import { mergeOverrides, useStyletron } from "baseui";
import type { PopoverOverrides } from "baseui/popover";
import type { Theme } from "baseui/theme";
import React from "react";
import { multiref, paddingHorizontal, paddingVertical } from "../utils";
import { useDebouncedFocusWithRef, useDebouncedHoverWithRef } from "../utils/debouncedEventHooks";
import { Row } from "./Row";
// eslint-disable-next-line no-restricted-imports
import { ACCESSIBILITY_TYPE, PLACEMENT, StatefulTooltip, Tooltip } from "baseui/tooltip";
import { defaultTo } from "lodash";
import type { ReactNode } from "react";
import type { KeyMapDisplayOptions } from "react-hotkeys-ce";
import type { StyleObject } from "styletron-react";
import { ShortcutKey, shortcutKeyText } from "./ShortcutKey";
// eslint-disable-next-line no-restricted-imports
export { ACCESSIBILITY_TYPE, PLACEMENT } from "baseui/tooltip";

export const keymapEntryToShortcutKeys = (keyMapEntry?: KeyMapDisplayOptions) => keyMapEntry?.sequences[0].sequence.toString().split("+");

export enum TOOLTIP_DELAY {
  short = 300,
  medium = 600,
  long = 1000,
}

export type ToolTipLabelSize = "medium" | "large";

export interface ToolTipOverrides {
  Outer?: {
    style: StyleObject;
  };
  Inner?: {
    style: StyleObject;
  };
  ToolTip?: PopoverOverrides;
}

export interface ToolTipProps {
  overrides?: ToolTipOverrides;
  label?: ReactNode;
  labelSize?: ToolTipLabelSize;
  shortcutKeys?: string[];
  children: ReactNode;
  kind?: "light" | "dark";
  placement?: PLACEMENT[keyof PLACEMENT];
  startEnhancer?: ReactNode;
  delay?: number | TOOLTIP_DELAY;
  ignoreBoundary?: boolean;
  inline?: boolean;
  isOpen?: boolean;
}

/** A popover element that shows when a given anchor element is hovered */
export const ToolTip = (props: ToolTipProps) => {
  const delay = props.delay ?? TOOLTIP_DELAY.medium;
  const [css, $theme] = useStyletron();
  const [isFocused, focusRef] = useDebouncedFocusWithRef<HTMLDivElement>({ autoRef: true, delay });
  const [isHovered, hoverRef] = useDebouncedHoverWithRef<HTMLDivElement>({ delay });
  const isOpen = props.isOpen || isFocused || isHovered;
  const hasLabel = !!props.label && (typeof props.label !== "string" || props.label.length > 0);
  const hasContent = hasLabel || !!props.startEnhancer || !!props.shortcutKeys;
  const placement = defaultTo(props.placement, PLACEMENT.top);
  const kind = defaultTo(props.kind, "dark");
  const outerStyle = props.inline
    ? { display: "inline", lineHeight: "initial", ...(props?.overrides?.Outer?.style || {}) }
    : { display: "inline-block", lineHeight: "initial", maxWidth: "100%", ...(props?.overrides?.Outer?.style || {}) };
  const Wrapper = props.inline ? "span" : "div";

  const overrides = tooltipOverrides($theme, props, kind);

  return (
    <Wrapper className={css(outerStyle)} ref={multiref(focusRef, hoverRef)}>
      <Tooltip
        ignoreBoundary={props.ignoreBoundary}
        accessibilityType={ACCESSIBILITY_TYPE.tooltip}
        isOpen={isOpen && hasContent}
        popoverMargin={6}
        triggerType="hover"
        content={
          <Row
            $gap={$theme.sizing.scale100}
            className={css({ color: kind == "dark" ? $theme.colors.contentInversePrimary : $theme.colors.contentPrimary })}
          >
            {props.startEnhancer}
            {hasLabel && <span>{props.label}</span>}
            {props.shortcutKeys &&
              props.shortcutKeys.map((key) => (
                <ShortcutKey $kind={kind} key={key}>
                  {shortcutKeyText(key)}
                </ShortcutKey>
              ))}
          </Row>
        }
        placement={placement}
        overrides={overrides}
      >
        <Wrapper className={css(props?.overrides?.Inner?.style || {})}>{props.children}</Wrapper>
      </Tooltip>
    </Wrapper>
  );
};

export interface AnchoredToolTipProps {
  overrides?: ToolTipOverrides;
  label?: ReactNode;
  labelSize?: ToolTipLabelSize;
  shortcutKeys?: string[];
  children: ReactNode;
  kind?: "light" | "dark";
  placement?: PLACEMENT[keyof PLACEMENT];
  startEnhancer?: ReactNode;
  delay?: number | TOOLTIP_DELAY;
  ignoreBoundary?: boolean;
  inline?: boolean;
}

/** A popover element that shows when a given anchor element is hovered */
export const AnchoredToolTip = (props: AnchoredToolTipProps) => {
  const [css, $theme] = useStyletron();
  const hasLabel = !!props.label && (typeof props.label !== "string" || props.label.length > 0);
  const placement = defaultTo(props.placement, PLACEMENT.top);
  const kind = defaultTo(props.kind, "dark");

  const overrides = tooltipOverrides($theme, props, kind);

  return (
    <StatefulTooltip
      ignoreBoundary={props.ignoreBoundary}
      accessibilityType={ACCESSIBILITY_TYPE.tooltip}
      popoverMargin={6}
      triggerType="hover"
      content={
        <Row
          $gap={$theme.sizing.scale100}
          className={css({ color: kind == "dark" ? $theme.colors.contentInversePrimary : $theme.colors.contentPrimary })}
        >
          {props.startEnhancer}
          {hasLabel && <span>{props.label}</span>}
          {props.shortcutKeys &&
            props.shortcutKeys.map((key) => (
              <ShortcutKey $kind={kind} key={key}>
                {shortcutKeyText(key)}
              </ShortcutKey>
            ))}
        </Row>
      }
      placement={placement}
      overrides={overrides}
    >
      {props.children}
    </StatefulTooltip>
  );
};

function tooltipOverrides($theme: Theme, props: AnchoredToolTipProps, kind: string) {
  return mergeOverrides(
    {
      Inner: {
        style: {
          ...$theme.typography.font100,
          ...(props.labelSize === "large" ? $theme.typography.LabelMedium : $theme.typography.LabelSmall),
          ...paddingVertical(props.label ? $theme.sizing.scale300 : $theme.sizing.scale100),
          ...paddingHorizontal(props.label ? $theme.sizing.scale400 : $theme.sizing.scale100),
          backgroundColor: "transparent",
          lineHeight: "initial",
          width: "100%",
        },
      },
      Body: {
        style: {
          backgroundColor: kind == "dark" ? $theme.colors.backgroundInversePrimary : $theme.colors.backgroundPrimary,
          boxShadow: kind == "light" ? "none" : undefined,
          pointerEvents: "none",
        },
      },
    },
    props.overrides?.ToolTip as any
  );
}
