import Emittery from "emittery";
import { head, isUndefined, remove } from "lodash";
import type { IObservableArray } from "mobx";
import { assert } from "../utils";
import type { SelectionMovement, SpaceNavigator } from "./SpaceNavigator";
import type { OnScreenLayout } from "./algorithm";
import { Direction, getCutoffCoordinate, measureLayout, priorityChildrenRelativeTo } from "./algorithm";

export enum MoveInitiator {
  Click = "Click",
  Drag = "Drag",
  Keyboard = "Keyboard",
  System = "System",
  // This is a move initiated by the system where we need to change the selectable when the selected item was removed
  DeletedReference = "Deleted",
}

export type FocusableContainerRef = React.RefObject<HTMLElement | undefined>;

export type ISpatialSelection = {
  selectedKey: string;
  hovered: IObservableArray<SelectableTarget>;
  isHovered(target: SelectableTarget): boolean;
  moveToSelectableTarget(target: SelectableTarget, moveInitiator: MoveInitiator): void;
};

/**
 * An optional function for imperatively moving the selection when a target is selected. Useful for implementing weird, non-spatial anomalies in the tree.
 * Here's example that this is useful for: a sub navigation + edit panel layout, where we have a left hand side column full of selectables that are tabs, and then a panel that shows inner content for the selected tab
 * -------------------
 * | foo |           |
 * | bar | <content> |
 * | baz |           |
 * -------------------
 * Each tab on the left (foo, bar, baz) should be a selectable so the user can navigate between the available tabs, and then when the user descends into one tab, the <content> will probably change and the selection should move into that. This is all standard spatial navigation.
 * There's a problem though: what happens when the user presses Escape at the outer most level of the <content> panel? Where's the intuitive place for the selection to go? If we just followed the tree, the selection would go up a level, probably to the root of the page, and be hidden. It moved out, which makes sense from an implementation perspective, but we don't think that makes sense for the user. Logically, they were *within* one of the tabs on the left, but the selection wasn't spatially within it.
 * So, we use reselection to implement better behaviour. For the selectable that contains all the content on the right, when the selection lands on it from within it, we imperatively move the selection to the tab that owns that content. We reselect to preserve the user's mental model of the nesting, violating the spatial tree but matching their expectations. This would be implemented as a reselector function that behaves normally when the selection passes down (indicated by the SelectionMovement argument), but when the selection moves up through it (SelectionMovement.FromWithin), returns a different selectable target by asking the SpaceNavigator to do stuff.
 */
export type SelectionReselector = (
  destination: SelectableTarget,
  origin: SelectableTarget,
  movement: SelectionMovement,
  navigator: SpaceNavigator<ISpatialSelection>
) => SelectableTarget | null | undefined | void;

export let objectIDCounter = 0;

export interface SelectableTargetOptions {
  selectableKey: string;
  engagementBoundary: boolean;
  elementRef?: React.RefObject<HTMLElement | SVGElement | undefined>;
  autoFocus?: boolean;
  onSelect?: (selectable: SelectableTarget) => void;
  reselector?: SelectionReselector;
}

/**
 * SelectionTarget represents one DOM node in the tree of selectable DOM nodes. It tracks it's children so it can run the spatial navigation algorithm. It's not a React component, but it corresponds exactly to one mounted instance of a component.
 * SelectionTarget also facilitates (but does not own) hover state and focus state. It acts as a central point to stick the callbacks that the React implementation can use as storage.
 *
 * __Note__: There can be more than one SelectionTarget for the same node/subnodekey pair. This is on purpose and supports selecting one node and seeing multiple DOM nodes representing that node light up as selected -- say the same logical node repeated as many concrete nodes in a table. In this case, each SelectionTarget will have a different ID and a different DOM node reference, but the same `node` and `subNodeKey` properties.
 */
export class SelectableTarget extends Emittery<{
  mount: SelectableTarget;
  unmount: SelectableTarget;
  select: SelectableTarget;
  childMounted: SelectableTarget;
}> {
  id: string;
  selectableKey: string;

  // Targets sometimes want navigation to pass through them to their parents or children, or have the navigation stop at them and require a user to continue in whatever direct their going. In this case, they are a boundary that requires engagement to pass, so they have `engagementBoundary: true`.
  engagementBoundary: boolean;

  // Targets that exist in space have a layout that lets us compute where it is relative to the other things in space, and navigate
  layout: OnScreenLayout | null = null;

  // Targets can be for elements or not for elements. Targets that not for elements can't actually be selected because they don't exist in space, like SelectionWormholes and FutureSelectableTargetDisclosures.
  elementRef: React.RefObject<HTMLElement | SVGElement | undefined> | undefined;
  existsInSpace: boolean;

  // Targets can imperatively direct the selection to a different place when they are about to be selected
  reselector?: SelectionReselector;

  children: SelectableTarget[] = [];
  childrenById: { [id: string]: SelectableTarget } = {};
  indexInParent: number | null = null;
  mounted = false;
  autoFocus = true;

  constructor(
    readonly navigator: SpaceNavigator<any>,
    readonly parent: SelectableTarget | null,
    readonly options: SelectableTargetOptions
  ) {
    super();
    this.id = String(objectIDCounter++);
    this.selectableKey = options.selectableKey;
    this.engagementBoundary = !!options.engagementBoundary;
    this.elementRef = options.elementRef;
    this.existsInSpace = !!this.elementRef;
    this.reselector = options.reselector;
  }

  updateStoredLayout() {
    if (this.existsInSpace) {
      if (this.elementRef?.current) {
        this.layout = measureLayout(this.elementRef.current);
      } else {
        throw new Error(
          `Can't update the layout for a SelectedTarget where its elementRef is null. You may have forgotten to pass the ref down to a real component. id=${this.id} selectableKey=${this.selectableKey}`
        );
      }
    }
  }

  mountChild(child: SelectableTarget) {
    if (this.childrenById[child.id]) {
      throw new Error(`Registering duplicate selectable on parent, id=${child.id}`);
    }

    const newLength = this.children.push(child);
    child.indexInParent = newLength - 1;
    this.childrenById[child.id] = child;
    this.navigator.registerSelectable(child);
    void this.emit("childMounted", child);
    return child;
  }

  unmountChild(selectable: SelectableTarget) {
    const child = assert(
      // eslint-disable-next-line lodash/prefer-immutable-method
      remove(this.children, { id: selectable.id })[0],
      `couldn't delete child because it couldn't be found. id=${selectable.id}`
    );
    delete this.childrenById[selectable.id];
    this.navigator.unregisterSelectable(child);
  }

  navigate(fromID: string, direction: Direction, exclusions?: string[]) {
    const current = assert(this.childrenById[fromID], "couldn't find current child in parent target's list of children");
    current.updateStoredLayout();
    const currentLayout = assert(
      current.layout,
      "cant navigate from a child that doesn't have a layout because we don't know where it is in space"
    );

    const isVerticalDirection = direction === Direction.DOWN || direction === Direction.UP;
    const isIncrementalDirection = direction === Direction.DOWN || direction === Direction.RIGHT;
    const currentCutoffCoordinate = getCutoffCoordinate(isVerticalDirection, isIncrementalDirection, false, currentLayout);

    // Filter the children down to things other than where we're starting, what's been excluded so far by the containing process, and then as an optimization, the list of children that are in the direction of the navigation, since they are the only candidates. Saves us comparing a bunch more selectables that aren't relevant.
    const candidates: SelectableTarget[] = [];

    for (const child of this.children) {
      if (child == current) continue; // can't navigate from this node to itself
      if (!child.elementRef) continue; // can't navigate to unmounted nodes
      if (exclusions?.includes(child.id)) continue; // don't navigate to nodes that we've been asked to exclude

      child.updateStoredLayout();
      const siblingCutoffCoordinate = getCutoffCoordinate(isVerticalDirection, isIncrementalDirection, true, assert(child.layout));

      if (
        isIncrementalDirection ? siblingCutoffCoordinate >= currentCutoffCoordinate : siblingCutoffCoordinate <= currentCutoffCoordinate
      ) {
        candidates.push(child);
      }
    }

    return head(priorityChildrenRelativeTo(currentLayout, candidates, direction));
  }

  navigateAsList(fromID: string, delta: number): SelectableTarget | undefined {
    const currentIndex = this.children.findIndex((child) => child.id == fromID);
    if (currentIndex == -1) {
      throw new Error("need to navigate from an ID that belongs to this target");
    }
    const destinationIndex = currentIndex + delta;
    return this.children[destinationIndex];
  }

  onFirstMount() {
    if (isUndefined(this.options.autoFocus) || this.options.autoFocus) {
      void this.once("mount").then(() => {
        this.focusAndScrollToElement();
      });
    }
  }

  onSelect(event: { moveInitiator: MoveInitiator }) {
    if (this.options.onSelect) {
      this.options.onSelect(this);
    }

    if (event.moveInitiator == MoveInitiator.System) {
      this.focusAndScrollToElement();
    }
  }

  focusAndScrollToElement() {
    if (!this.elementRef || !this.elementRef.current) {
      return;
    }

    if (this.navigator.debug) console.debug("space-node-autofocus", this.elementRef.current);
    if (document.activeElement != this.elementRef?.current) {
      this.elementRef.current.focus();
      if (this.elementRef.current && "scrollIntoViewIfNeeded" in this.elementRef.current) {
        (this.elementRef.current as any).scrollIntoViewIfNeeded(true);
      }
      if (this.navigator.debug) console.debug("space-node-autofocus-result", { activeElement: document.activeElement });
    }
  }
}
