import { clamp, isNull, remove } from "lodash";
import { assert } from "../utils";
import type { ISpatialSelection } from "./SelectableTarget";
import { SelectableTarget } from "./SelectableTarget";
import { Direction } from "./algorithm";

export enum SelectionMovement {
  FromLeft = "FromLeft",
  FromRight = "FromRight",
  FromBelow = "FromBelow",
  FromAbove = "FromAbove",
  FromWithin = "FromWithin",
  FromContainer = "FromContainer",
  FromNext = "FromNext",
  FromPrevious = "FromPrevious",
  FromRemoval = "FromRemoval",
  Direct = "Direct",
}

export type SpaceNavigatorOptions<Selection extends ISpatialSelection> = {
  onRemoved: SpaceNavigator<Selection>["callbacks"]["onRemoved"];
  onInternalMove: SpaceNavigator<Selection>["callbacks"]["onInternalMove"];
  onClick: SpaceNavigator<Selection>["callbacks"]["onInternalMove"];
};

/**
 * `SpaceNavigator` watches a changing tree of React components and bookkeeps what can be focused on.
 * It answers the question what should be selected next. It doesn't store the state of what is selected -- the Selection does that -- so each navigation interrogator takes a Selection and computes what would be selected next for that Selection. It supports asking about
 * - spatial selection, like what's next upwards or downwards in space, for an Up or Down arrow keypress
 * - ordered selection like what's next overall, for a Tab keypress
 * - nesting level selection, like what's the containing selection or the first child selection, for an Escape or Enter keypress
 *
 * Actually manipulating the selected state is outside the scope of this object, so that other logic around what can be selected when can be applied there.
 *
 * Users can select and navigate among `node`s. Nodes are anything that have a `key` which uniquely identifies them.  A selection is powered by `node`s and `subNodeKey`
 *
 * ### Multiselect
 * `SpaceNavigator` (and `SelectionTarget`) support _multiple targets for the same node_. This is because the node tree can diverge from the DOM tree. For Gadget views for example, the node tree represents an abstract version of the rendered structure.  There isn't one node in the view tree per table row, but there is one DOM node in the DOM per table row for example. `SpaceNavigator` consumers can feed in the same node (and subNodeKey) for multiple selectable React components, and `SpaceNavigator` will report _all_ of them as selected when the cursor navigates to the state tree node they all correspond to. Mind bendy.
 *
 * So, `SelectionTarget` represents exactly one DOM node, and multiple `SelectionTarget`s can represent the same node/subNodeKey at once.
 */
export class SpaceNavigator<Selection extends ISpatialSelection> {
  debug = false;
  mounted = false;
  // all the descendants, children/grandchildren/grandgrand etc, used for lookups by ID for a specific target
  private readonly idIndex: { [id: string]: SelectableTarget } = {};

  // all the descendants, children/grandchildren/grandgrand etc, used for lookups by key for a specific node
  private readonly keyIndex: { [key: string]: SelectableTarget[] } = {};

  readonly callbacks: {
    // Space navigator will report when children are unmounted. Useful for moving the selection to another child when this happens
    readonly onRemoved: (navigator: SpaceNavigator<Selection>, selectable: SelectableTarget) => void;

    // Space navigator will sometimes need the selection to move for internal reasons. This gets called with the new selectable when that happens
    readonly onInternalMove: (navigator: SpaceNavigator<Selection>, newTarget: SelectableTarget) => void;

    // Spave navigator fires th
    readonly onClick: (navigator: SpaceNavigator<Selection>, newTarget: SelectableTarget) => void;
  };

  constructor(options: SpaceNavigatorOptions<Selection>) {
    this.callbacks = { onRemoved: options.onRemoved, onInternalMove: options.onInternalMove, onClick: options.onClick };
  }

  getSelectableById(id: string) {
    if (this.idIndex[id]) {
      return this.idIndex[id];
    }
    return null;
  }

  getAllSelectables(): SelectableTarget[];
  getAllSelectables(selectableKey: string): SelectableTarget[] | null;
  getAllSelectables(selectableKey?: string): SelectableTarget[] | null {
    if (typeof selectableKey != "undefined") {
      return this.keyIndex[selectableKey];
    } else {
      return Object.values(this.keyIndex).flat();
    }
  }

  // Registration method used internally by this nesting level and by descendant SelectionTarget nesting levels
  registerSelectable(selectable: SelectableTarget) {
    if (this.idIndex[selectable.id]) {
      console.error("duplicate selectable registration", {
        existing: this.idIndex[selectable.id],
        new: selectable,
      });
      throw new Error(`Duplicate selectable being registered id=${selectable.id}`);
    }

    if (this.debug) {
      console.debug("registering selectable", selectable, this);
    }

    if (!this.keyIndex[selectable.selectableKey]) this.keyIndex[selectable.selectableKey] = [];
    this.keyIndex[selectable.selectableKey].push(selectable);
    this.idIndex[selectable.id] = selectable;
  }

  unregisterSelectable(selectable: SelectableTarget) {
    if (this.debug) {
      console.debug("un-registering selectable", selectable, this);
    }

    assert(
      // eslint-disable-next-line lodash/prefer-immutable-method
      remove(this.keyIndex[selectable.selectableKey], { id: selectable.id })[0],
      `couldn't delete child because it couldn't be found. id=${selectable.id}`
    );

    delete this.idIndex[selectable.id];
    this.callbacks.onRemoved(this, selectable);
  }

  mountChild(selectable: SelectableTarget) {
    this.registerSelectable(selectable);
  }

  unmountChild(selectable: SelectableTarget) {
    assert(selectable.parent == null, "trying to removeChild from SpaceNavigator that isnt a direct child"); // should instead use unregisterSelectable if it's a descendant
    this.unregisterSelectable(selectable);
  }

  /**
   * Checks if a given node (with optional sub key) is currently selected by the given selection.
   * Can pass the node and subNodeKey as arguments, or pass a Selectable representing that node/key pair as well.
   */
  isSelected(selection: Selection, selectableOrKey: SelectableTarget | string) {
    if (selectableOrKey instanceof SelectableTarget) {
      return selection.selectedKey == selectableOrKey.selectableKey;
    } else {
      return selection.selectedKey == selectableOrKey;
    }
  }

  /**
   * Investigates see if the given key is the currently selected key or a parent of the currently selected key in the selection tree.
   * Returns false if the given key is selected or if the selection is *within* the parent, returns false otherwise.
   * Note: this operates in the *selection* tree, not the *state tree*. A child passes this test if one of it's parents in the DOM is selected, because that's what users see and might expect. Checking if a child is selected in the *state tree* isn't really an operation that can be defined well, because not all nodes in the state tree have a representation as a selectable node in the DOM, and the state tree has references that mean strict-parentship isn't always what you care about.
   */
  isSelectedOrAncestorOfSelected(selectableOrKey: SelectableTarget | string, selection: Selection) {
    const testKey = selectableOrKey instanceof SelectableTarget ? selectableOrKey.selectableKey : selectableOrKey;
    let cursor: SelectableTarget | null = this.firstSelectedSelectableTarget(selection);

    while (cursor) {
      if (cursor.selectableKey == testKey) {
        return true;
      }
      cursor = cursor.parent;
    }
    return false;
  }

  /**
   * Investigates see if the given key is a parent of the currently selected key in the selection tree.
   * Returns false if the given key is the selection or outside the selection, returns true if the selection is *within* the key.
   * Note: this operates in the *selection* tree, not the *state tree*. A child passes this test if one of it's parents in the DOM is selected, because that's what users see and might expect. Checking if a child is selected in the *state tree* isn't really an operation that can be defined well, because not all nodes in the state tree have a representation as a selectable node in the DOM, and the state tree has references that mean strict-parentship isn't always what you care about.
   */
  isAncestorOfSelected(selectableOrKey: SelectableTarget | string, selection: Selection) {
    const testKey = selectableOrKey instanceof SelectableTarget ? selectableOrKey.selectableKey : selectableOrKey;
    let cursor: SelectableTarget | null = this.firstSelectedSelectableTarget(selection);
    if (cursor) cursor = cursor.parent;

    while (cursor) {
      if (cursor.selectableKey == testKey) {
        return true;
      }
      cursor = cursor.parent;
    }
    return false;
  }

  // Return the first SelectionTarget matching the node selected by the Selection. Used as the anchor for navigation.
  firstSelectedSelectableTarget(selection: Selection): SelectableTarget | null {
    const targetsForKey = this.keyIndex[selection.selectedKey];
    if (!targetsForKey || !targetsForKey[0]) {
      return null;
    }
    return targetsForKey[0];
  }

  /**
   * Spatially determines the next SelectableTarget in a given direction
   * Tries to find an sibling of the current selection in the direction specified, then tries to find a sibling of a parent in the direction, then if nothing can be found selects the immediate parent if going up or down
   */
  navigate(selection: Selection, direction: Direction): SelectableTarget | null {
    const firstSelectable = this.firstSelectedSelectableTarget(selection);
    if (!firstSelectable) return null;
    let fromID = firstSelectable.id;
    let cursor: SelectableTarget | null = firstSelectable;
    const exclusions: string[] = [fromID];

    while (cursor && cursor.parent) {
      const result = cursor.parent.navigate(fromID, direction, exclusions);
      if (result) {
        const destination = this.dive(result);
        return this.reselect(firstSelectable, destination, this.movementForDirection(direction));
      } else {
        // we didn't find somewhere to navigate that is a sibling of the cursor, so hop up a level in the tree to see if there is an aunt or uncle to navigate to (unless this tree level has to be explicitly exited)
        if (!cursor.parent.engagementBoundary) {
          // exclude this parent from the search at the next node up
          exclusions.push(cursor.id);
          fromID = cursor.parent.id;
          cursor = cursor.parent;
        } else {
          break;
        }
      }
    }

    return null;
  }

  navigateAsList(selection: Selection, delta: number): SelectableTarget | null {
    const firstSelectable = this.firstSelectedSelectableTarget(selection);
    if (!firstSelectable) return null;
    let fromID = firstSelectable.id;
    let cursor: SelectableTarget | null = firstSelectable;
    while (cursor.parent) {
      const result = cursor.parent.navigateAsList(fromID, delta);
      if (result) {
        const destination = this.dive(result);
        return this.reselect(firstSelectable, destination, delta > 0 ? SelectionMovement.FromPrevious : SelectionMovement.FromNext);
      } else {
        // we didn't find somewhere to navigate that is a sibling of the cursor, so hop up a level in the tree to see if there is an aunt or uncle to navigate to (unless this tree level has to be explicitly exited)
        if (!cursor.parent.engagementBoundary) {
          // exclude this parent from the search at the next node up
          fromID = cursor.parent.id;
          cursor = cursor.parent;
        } else {
          break;
        }
      }
    }
    return null;
  }

  navigateIn(selection: Selection): SelectableTarget | null {
    const firstSelectable = this.firstSelectedSelectableTarget(selection);
    if (!firstSelectable) return null;

    const destination = firstSelectable.children[0];
    if (destination) return this.reselect(firstSelectable, destination, SelectionMovement.FromContainer);
    return null;
  }

  navigateOut(selection: Selection): SelectableTarget | null {
    const firstSelectable = this.firstSelectedSelectableTarget(selection);
    if (!firstSelectable) return null;

    const destination = firstSelectable.parent;
    if (destination) return this.reselect(firstSelectable, destination, SelectionMovement.FromWithin);
    return null;
  }

  /**
   * Navigate to the most intuitive place as a selectable gets removed.
   * If that selectable has siblings, we should go to the closest one preferring logically prior ones, and if there's no siblings we go up to the parent.
   * If the parent is also gone, we go up to the parent's parent recursively.
   * Currently doesn't dive into the result because that seems like it violates the user's mental model if they're operating on a list -- they removed one list element, so they'd expect the selection to end up within the list still, not potentially down in an adjacent list item to then only have to go back up to delete the list item.
   */
  navigateOnRemovalOfSelected(
    selectable: SelectableTarget,
    validDestinationChecker?: (target: SelectableTarget) => boolean
  ): SelectableTarget {
    let cursor: SelectableTarget | null = selectable;
    let removedChild = selectable;

    // Climb the parents until we find one that is still mounted
    while (cursor) {
      removedChild = cursor;
      cursor = cursor.parent;
      if (cursor && this.idIndex[cursor.id] && (!validDestinationChecker || validDestinationChecker(cursor))) {
        break;
      }
    }

    if (!cursor) {
      throw new Error(`couldn't find a still-existing parent to navigate to on node removal!`);
    }

    // If we have found a navigatable cursor, and it is the parent of the removed node, we should navigate to the closest sibling of the removed node, as opposed to the parent itself. Delete one thing in a list, end up at another other thing in the list, or the parent if it's empty
    if (cursor == removedChild.parent && !isNull(removedChild.indexInParent)) {
      // The child has been removed by the time this function executes, so the parent's children array is now up to date and doesn't have the node in it.
      // This means the child's indexInParent is out of date, but now if it points to something in the new array, we can navigate to it! This implements nice behaviour of accessing the previous sibling if it exists, or the next sibling if the removed node was index 0 without having to do lots of checks.
      const nextSibling = cursor.children[clamp(removedChild.indexInParent, 0, cursor.children.length - 1)];
      if (nextSibling && (!validDestinationChecker || validDestinationChecker(nextSibling))) {
        return this.reselect(selectable, nextSibling, SelectionMovement.FromRemoval);
      }
    }

    return cursor;
  }

  /**
   * Returns the destination selectable target when a given target is directly requested to be selected, like on click. Seems silly -- almost always, you want to go to the target that is clicked, but to preserve features like reselection, clicks should pass through this method.
   */
  navigateDirectly(selection: Selection, selectable: SelectableTarget): SelectableTarget {
    const firstSelectable = this.firstSelectedSelectableTarget(selection);
    return this.reselect(firstSelectable || selectable, selectable, SelectionMovement.Direct);
  }

  /**
   * Descend as many levels as possible into a tree to make as specific a selection as possible when navigating
   * Diving has to stop at engagement boundaries, leaves of the tree, and maybe some other cases where it would be ambiguous or annoying for the user.
   */
  private dive(selectable: SelectableTarget): SelectableTarget {
    if (selectable.engagementBoundary) {
      return selectable;
    } else if (selectable.children.length == 0) {
      return selectable;
    } else {
      return this.dive(selectable.children[0]);
    }
  }

  private reselect(origin: SelectableTarget, destination: SelectableTarget, movement: SelectionMovement): SelectableTarget {
    if (destination.reselector) {
      destination = destination.reselector(destination, origin, movement, this) || destination;
    }
    return destination;
  }

  private movementForDirection(direction: Direction) {
    if (direction == Direction.DOWN) {
      return SelectionMovement.FromAbove;
    } else if (direction == Direction.UP) {
      return SelectionMovement.FromBelow;
    } else if (direction == Direction.LEFT) {
      return SelectionMovement.FromLeft;
    } else {
      return SelectionMovement.FromRight;
    }
  }
}
