import React from "react";
import type { ISpatialSelection, SelectableTarget } from "./SelectableTarget";
import type { SpaceNavigator } from "./SpaceNavigator";

export const SpaceNavigatorContext = React.createContext<SpaceNavigator<any> | null>(null);

export const SelectionContext = React.createContext<ISpatialSelection | null>(null);

// Represents the thing that children should be attached to.
// If we're nesting selectables with engagement boundaries or whatever, the current selectable will be a SelectionTarget
// If we don't have an existing selectable, we're at the root, so selectables will be registered directly with the space navigator
// SpaceNavigator and SelectableTarget share an interface that allows registering children
export const CurrentSelectableContext = React.createContext<SelectableTarget | SpaceNavigator<any>>(null as any);

// Root context that manages the whole space
export const SpaceNavigatorRoot = <Selection extends ISpatialSelection>(props: {
  selection: Selection;
  spaceNavigator: SpaceNavigator<Selection>;
  children?: React.ReactNode;
}) => {
  React.useEffect(() => {
    props.spaceNavigator.mounted = true;
    return () => {
      props.spaceNavigator.mounted = false;
    };
  }, [props.spaceNavigator]);

  return (
    <SelectionContext.Provider value={props.selection}>
      <SpaceNavigatorContext.Provider value={props.spaceNavigator}>
        <CurrentSelectableContext.Provider value={props.spaceNavigator}>{props.children}</CurrentSelectableContext.Provider>
      </SpaceNavigatorContext.Provider>
    </SelectionContext.Provider>
  );
};
