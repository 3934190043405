import type { Theme } from "baseui/theme";
import { omit } from "lodash";
import { Observer } from "mobx-react-lite";
import React, { forwardRef, useMemo, useRef } from "react";
import type { StyleObject } from "styletron-react";
import { multiref } from "../utils";
import { ClickCaptureContainer, ClickCaptureLayer } from "./ClickCapture";
import type { SelectableTarget, SelectableTargetOptions, SelectionReselector } from "./SelectableTarget";
import { CurrentSelectableContext } from "./contexts";
import type { SelectableMouseEventHandlers, SelectionState } from "./hooks";
import { packSelectableKey, useSelectableMouseEventHandlers, useSelectableTarget, useSelectedStyles, useSelectionState } from "./hooks";

export interface SelectableNode {
  key: string;
  type: string;
}

export interface SelectableTargetForwardProps extends SelectableMouseEventHandlers {
  "data-selectable-id": string;
  "data-selected": string;
  "data-selectable-is-selected": boolean;
  tabIndex: number;
  ref: React.MutableRefObject<any>;
  style: StyleObject;
}

// Low level context helper for creating new selection targets that have to be explicitly entered into before their children are focusable
type SelectionTargetSetupProps = {
  engagementBoundary?: boolean;
  showSelection?: boolean;
  autoFocus?: boolean;
  getStyles?: (styles: StyleObject, isSelected: boolean) => StyleObject;
  reselectWhenSelected?: SelectionReselector;
  onSelect?: (selectable: SelectableTarget) => void;
} & ({ selectableKey: string } | { node: SelectableNode; subNodeKey?: string }); // hack to make passing selectableKeys easier: you can pass the string key itself, or pass a node and a subNodeKey, which technically spatial-navigation-tree shouldn't know about, but its really annoying to pack the key everywhere

export const SelectableTargetSetup = (
  props: SelectionTargetSetupProps & {
    children: (forwardProps: SelectableTargetForwardProps, selectionState: SelectionState) => React.ReactNode;
  }
) => {
  const elementRef = useRef<HTMLElement | SVGElement>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectableKey = useMemo(
    () => ("selectableKey" in props ? props.selectableKey : packSelectableKey(props.node, props.subNodeKey)),
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (props as any).selectableKey,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (props as any).node,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (props as any).subNodeKey,
    ]
  );

  // useWhyDidYouUpdate("selectable-target", {
  //   engagementBoundary: props.engagementBoundary,
  //   onSelect: props.onSelect,
  //   selectableKey,
  // });

  const options: SelectableTargetOptions = useMemo((): SelectableTargetOptions => {
    return {
      selectableKey,
      elementRef,
      engagementBoundary: !!props.engagementBoundary,
      autoFocus: props.autoFocus,
      onSelect: props.onSelect,
      reselector: props.reselectWhenSelected,
    };
  }, [props.engagementBoundary, props.autoFocus, props.onSelect, props.reselectWhenSelected, selectableKey]);

  const selectable = useSelectableTarget(options);

  return (
    <Observer>
      {() => {
        // Disable the hooks eslint checks because we're actually in a nested component here, eslint just doesn't know it
        /* eslint-disable react-hooks/rules-of-hooks */
        const showSelection = typeof props.showSelection == "undefined" ? true : props.showSelection;
        const selectionState = useSelectionState(selectable);
        const selectedStyles = useSelectedStyles(selectable, props.getStyles);
        const { isSelected } = useSelectionState(selectable);

        const forwardProps: SelectableTargetForwardProps = {
          "data-selectable-is-selected": isSelected,
          "data-selectable-id": selectable.id,
          "data-selected": String(selectionState.isSelected),
          tabIndex: 0,
          ref: elementRef,
          style: showSelection ? selectedStyles : {},
          ...useSelectableMouseEventHandlers(selectable),
        };

        return (
          <CurrentSelectableContext.Provider value={selectable}>
            {props.children(forwardProps, selectionState)}
          </CurrentSelectableContext.Provider>
        );
        /* eslint-enable react-hooks/rules-of-hooks */
      }}
    </Observer>
  );
};

// High level wrapper component that does three things:
// 1 - show the selection border styles around it's children if the props.node is selected by adding a wrapper div
// 2 - sets up another selection target that will remember which one of it's children was selected last time it was active
// 3 - optionally requires explicit entry into the selection target via another interaction to allow hiding/showing selectables
export const SelectableContainer = forwardRef(
  (
    props: SelectionTargetSetupProps & { className?: string; $style?: StyleObject; children: React.ReactNode; captureClicks?: boolean },
    ref: React.Ref<any>
  ) => {
    return (
      <SelectableTargetSetup {...props}>
        {(forwardProps, selectionState) => {
          return (
            <ClickCaptureContainer
              {...omit(forwardProps, "ref")}
              data-selectable-id={selectionState.selectable.id}
              className={props.className}
              $style={props.$style}
              ref={multiref(ref, forwardProps.ref)}
            >
              {props.children}
              {props.captureClicks && <ClickCaptureLayer />}
            </ClickCaptureContainer>
          );
        }}
      </SelectableTargetSetup>
    );
  }
);

// High level render prop component that avoids adding a wrapper div, but requires forwarding all the props to one child / among children.
export const ConfigureSelectable = (
  props: SelectionTargetSetupProps & {
    children: (forwardProps: SelectableTargetForwardProps, selectionState: SelectionState) => React.ReactNode;
  }
) => {
  return <SelectableTargetSetup {...props} />;
};

export const getCardSelectionStyle =
  (hasBackgroundStyle = false, $theme: Theme) =>
  (styles: StyleObject, isSelected: boolean) => {
    if (isSelected) {
      styles.zIndex = 100;
      styles.position = "relative";
    }

    if (hasBackgroundStyle) {
      styles.backgroundColor = isSelected ? $theme.colors.electricBlue50 : $theme.colors.primary50;
    }

    return styles;
  };
