import { sortBy } from "lodash";
// Spatial navigation algorithm, copied mostly from https://github.com/NoriginMedia/react-spatial-navigation/blob/master/src/spatialNavigation.js
// Had to fork to make it explicit level aware and play nicer with our MST based thing instead of react component based thing
import { assert } from "../utils";
import type { SelectableTarget } from "./SelectableTarget";

const ADJACENT_SLICE_THRESHOLD = 0.2;

/**
 * Adjacent slice is 5 times more important than diagonal
 */
const ADJACENT_SLICE_WEIGHT = 5;
const DIAGONAL_SLICE_WEIGHT = 1;

/**
 * Main coordinate distance is 5 times more important
 */
const MAIN_COORDINATE_WEIGHT = 5;

export enum Direction {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
}

export interface OnScreenLayout {
  // x: number;
  // y: number;
  width: number;
  height: number;
  left: number;
  top: number;
}

const isHTMLElement = (node: Node): node is HTMLElement => {
  return node.nodeType == 1;
};

export const measureLayout = (node: Element) => {
  const parent = node.parentNode;
  if (!parent || !isHTMLElement(parent)) {
    throw new Error("can't measure unmounted node");
  }
  return node.getBoundingClientRect();
};

/**
 * Used to determine the coordinate that will be used to filter items that are over the "edge"
 */
export function getCutoffCoordinate(isVertical: boolean, isIncremental: boolean, isSibling: boolean, layout: OnScreenLayout) {
  const itemX = layout.left;
  const itemY = layout.top;
  const itemWidth = layout.width;
  const itemHeight = layout.height;

  const coordinate = isVertical ? itemY : itemX;
  const itemSize = isVertical ? itemHeight : itemWidth;

  if (isIncremental) {
    return isSibling ? coordinate : coordinate + itemSize;
  } else {
    return isSibling ? coordinate + itemSize : coordinate;
  }
}

export interface Corners {
  a: {
    x: number;
    y: number;
  };
  b: {
    x: number;
    y: number;
  };
}

/**
 * Returns two corners (a and b) coordinates that are used as a reference points
 * Where "a" is always leftmost and topmost corner, and "b" is rightmost bottommost corner
 */
export function getRefCorners(direction: Direction, isSibling: boolean, layout: OnScreenLayout): Corners {
  const itemX = layout.left;
  const itemY = layout.top;
  const itemWidth = layout.width;
  const itemHeight = layout.height;

  const result = {
    a: {
      x: 0,
      y: 0,
    },
    b: {
      x: 0,
      y: 0,
    },
  };

  switch (direction) {
    case Direction.UP: {
      const y = isSibling ? itemY + itemHeight : itemY;

      result.a = {
        x: itemX,
        y,
      };

      result.b = {
        x: itemX + itemWidth,
        y,
      };

      break;
    }

    case Direction.DOWN: {
      const y = isSibling ? itemY : itemY + itemHeight;

      result.a = {
        x: itemX,
        y,
      };

      result.b = {
        x: itemX + itemWidth,
        y,
      };

      break;
    }

    case Direction.LEFT: {
      const x = isSibling ? itemX + itemWidth : itemX;

      result.a = {
        x,
        y: itemY,
      };

      result.b = {
        x,
        y: itemY + itemHeight,
      };

      break;
    }

    case Direction.RIGHT: {
      const x = isSibling ? itemX : itemX + itemWidth;

      result.a = {
        x,
        y: itemY,
      };

      result.b = {
        x,
        y: itemY + itemHeight,
      };

      break;
    }

    default:
      break;
  }

  return result;
}

/**
 * Calculates if the compare node is intersecting enough with the ref node by the secondary coordinate
 */
export function isAdjacentSlice(refCorners: Corners, compareCorners: Corners, isVerticalDirection: boolean) {
  const { a: refA, b: refB } = refCorners;
  const { a: compareA, b: compareB } = compareCorners;
  const coordinate = isVerticalDirection ? "x" : "y";

  const refCoordinateA = refA[coordinate];
  const refCoordinateB = refB[coordinate];
  const compareCoordinateA = compareA[coordinate];
  const compareCoordinateB = compareB[coordinate];

  const thresholdDistance = (refCoordinateB - refCoordinateA) * ADJACENT_SLICE_THRESHOLD;

  const intersectionLength = Math.max(0, Math.min(refCoordinateB, compareCoordinateB) - Math.max(refCoordinateA, compareCoordinateA));

  return intersectionLength >= thresholdDistance;
}

export function getPrimaryAxisDistance(refCorners: Corners, compareCorners: Corners, isVerticalDirection: boolean) {
  const { a: refA } = refCorners;
  const { a: compareA } = compareCorners;
  const coordinate = isVerticalDirection ? "y" : "x";

  return Math.abs(compareA[coordinate] - refA[coordinate]);
}

export function getSecondaryAxisDistance(refCorners: Corners, compareCorners: Corners, isVerticalDirection: boolean) {
  const { a: refA, b: refB } = refCorners;
  const { a: compareA, b: compareB } = compareCorners;
  const coordinate = isVerticalDirection ? "x" : "y";

  const refCoordinateA = refA[coordinate];
  const refCoordinateB = refB[coordinate];
  const compareCoordinateA = compareA[coordinate];
  const compareCoordinateB = compareB[coordinate];

  const distancesToCompare = [];

  distancesToCompare.push(Math.abs(compareCoordinateA - refCoordinateA));
  distancesToCompare.push(Math.abs(compareCoordinateA - refCoordinateB));
  distancesToCompare.push(Math.abs(compareCoordinateB - refCoordinateA));
  distancesToCompare.push(Math.abs(compareCoordinateB - refCoordinateB));

  return Math.min(...distancesToCompare);
}

/**
 * Inspired by: https://developer.mozilla.org/en-US/docs/Mozilla/Firefox_OS_for_TV/TV_remote_control_navigation#Algorithm_design
 * Ref Corners are the 2 corners of the current component in the direction of navigation
 * They used as a base to measure adjacent slices
 */
export function priorityChildrenRelativeTo(relativeToLayout: OnScreenLayout, candidates: SelectableTarget[], direction: Direction) {
  const isVerticalDirection = direction === Direction.DOWN || direction === Direction.UP;

  const refCorners = getRefCorners(direction, false, relativeToLayout);

  return sortBy(candidates, (candidate) => {
    const candidateCorners = getRefCorners(direction, true, assert(candidate.layout));
    const candidateIsAdjacentSlice = isAdjacentSlice(refCorners, candidateCorners, isVerticalDirection);
    const primaryAxisFunction = candidateIsAdjacentSlice ? getPrimaryAxisDistance : getSecondaryAxisDistance;
    const secondaryAxisFunction = candidateIsAdjacentSlice ? getSecondaryAxisDistance : getPrimaryAxisDistance;

    const primaryAxisDistance = primaryAxisFunction(refCorners, candidateCorners, isVerticalDirection);
    const secondaryAxisDistance = secondaryAxisFunction(refCorners, candidateCorners, isVerticalDirection);

    /**
     * The higher this value is, the less prioritised the candidate is
     */
    const totalDistancePoints = primaryAxisDistance * MAIN_COORDINATE_WEIGHT + secondaryAxisDistance;

    /**
     * + 1 here is in case of distance is zero, but we still want to apply Adjacent priority weight
     */
    const priority = (totalDistancePoints + 1) / (candidateIsAdjacentSlice ? ADJACENT_SLICE_WEIGHT : DIAGONAL_SLICE_WEIGHT);

    return priority;
  });
}
